import { map, Observable } from 'rxjs';
import { Exchange } from 'models/Exchange';
import { ExchangeInfo } from 'models/ExchangeInfo';
import { PagedResponse } from 'models/Responses/PagedResponse';
import { HttpClient } from 'utils/http/httpClient.models';

export interface ExchangesApi {
  getDefinitions(): Observable<PagedResponse<Exchange>>;
  getInfo(): Observable<ExchangeInfo[]>;
}

export const exchangesApiConnector = (httpClient: HttpClient): ExchangesApi => ({
  getDefinitions(): Observable<PagedResponse<Exchange>> {
    return httpClient
      .getJSON<PagedResponse<Exchange>>({ url: `/api/v1/exchanges` })
      .pipe(map(({ payload }) => payload));
  },
  getInfo(): Observable<ExchangeInfo[]> {
    return httpClient.getJSON<ExchangeInfo[]>({ url: `/api/v1/exchanges/info` }).pipe(map(({ payload }) => payload));
  },
});
